import React from "react"

// import "../utils/scripts/smoothScroll"

import Header from "../components/CareersPage/Header"
import CareerForm from "../components/CareersPage/CareerForm"

// import "../utils/animation"

import "../utils/css/careerPage.css"

const CareersPage = function () {
  return (
    <div className="careerhome">
      <Header />
      <div className="careertitle">
        <span className="careername1">INTERESTED IN WORKING </span>
        <span
          style={{
            "text-decoration": "line-through",
          }}
        >
          FOR
        </span>
        <span> WITH US?</span>
        <br class="hidden-xs"></br>
        <p className="careertag1">
          We are a team of designers, inventors, creators and innovators,
          <br class="hidden-xs"></br>
          above all we are a team of dreamers.
        </p>
      </div>
      <CareerForm />
    </div>
  )
}

export default () => (
  <div>
    <CareersPage />
    {/* <SEO />
        <Header />
        <FilterNav />
        <HomePage>
            <ArticlesGrid />
            <Footer />
        </HomePage> */}
  </div>
)
