import React from "react"

import NavbarNormal from "../Navigation/NavbarNormal"
import NavbarMobile from "../Navigation/NavbarMobile"
// import ITLLogo from "../assets/images/itl_logo.png"

export default function Header() {
  return (
    <header className="header">
      {/* <img className="logo" src={ ITLLogo } /> */}
      <NavbarNormal page="" />
      <NavbarMobile page="" />
    </header>
  )
}
